import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import {
  provideRouter,
  withViewTransitions,
  withRouterConfig,
} from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from './common/interceptors/jwt.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID } from '@angular/core';
import { HAMMER_LOADER, HammerModule } from '@angular/platform-browser';

registerLocaleData(localeEs); // Registra el locale español

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withViewTransitions({ skipInitialTransition: true })),
    provideHttpClient(withInterceptors([JwtInterceptor])),
    provideAnimations(),
    importProvidersFrom(
      HammerModule
    ),
    { provide: HAMMER_LOADER, useValue: () => import('hammerjs') }, 
    { provide: LOCALE_ID, useValue: 'es' }, // Establece el locale a español
  ],
};
